<!--
 * @Author: 张阳帅
 * @Date: 2024-10-08 09:52:37
 * @LastEditTime: 2024-10-14 14:51:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\msg\msgList.vue
 * -->
<template>
  <div class="msglist_page">
    <page-refresh-load :data-source="dataSource" :total="total" :refreshing="refreshing" :loading="loading" @refreshing="onRefresh" @load-more="onLoad">
      <div v-for="(item,index) in dataSource" :key="index" class="page_main" @click="getMsgDetailAction(item)">
        <div class="main_item">
          <div class="item_title">
            <div class="title_cont">
              <van-badge v-if="item.sfyd!='1'" dot />
              {{ item.xxbt }}
            </div>
            <div class="title_time">
              {{ item.xxfssj }}
            </div>
          </div>
          <div class="item_info">
            <div class="info_name">
              {{ item.xxnr }}
            </div>
          </div>
        </div>
      </div>  
    </page-refresh-load>
  </div>
</template>
<script>
import { gjcModel } from '@/api/gjcApi'
import pageRefreshLoad from '@/assets/js/mixin/pageRefreshLoad'
import {SET_HEADER_CONFIG} from '../../../store/mutation-types'

export default {
    mixins:[pageRefreshLoad],
    data(){
        return{
            page:1, //页码
            total:0, //总条数
            loading:false,
            refreshing:false,
            dataSource:[] //消息列表
        }
    },
    mounted() {
        this.$store.commit(`header/${SET_HEADER_CONFIG}`, { title:'消息列表',showRightBtn: true,rightBtnText: '全部已读', rightBtnActive: false,emitEventByActive: true, clickRightBtn:this.clickRightBtnAction })
        this.onRefresh()
    },
    methods:{
        async getList(){
            if(this.page==1){
                this.dataSource=[]
            }
            const params = {
                yydm: '',
                page: this.page,
                pageSize:'10',
                fszt: "1", //发送状态 1 成功 0失败
                xxlx:'1'
            }
            const res = await gjcModel.getMsgPageListData(params)
            console.log('获取消息列表', res)
            this.dataSource.push(...(res.data || []))
            this.total = res.count || 0
            this.loading = false
        },
        //消息详情
        getMsgDetailAction(item){
            this.$router.push({
                path: '/msgDetail',
                query: {
                    id: item.id
                }
            })
        },
        //全部已读
        clickRightBtnAction() {
            console.log('全部--已读')
            gjcModel.getAllMsgReadData().then(res=>{
                console.log('全部已读', res)
                this.getList()
            })
        }
    }
}
</script>
<style scoped lang='less'>
.msglist_page{
    background-color: #f5f5f5;
}
.page_main{
    background-color: white;
    overflow: auto;
    .main_item{
        padding: 20px 24px;
        border-bottom: 20px solid #f5f5f5;
        .item_title{
            display: flex;
            justify-content: space-between;
            margin: 0 0 10px 0;
            .title_cont{
                font-size: 28px;
                color: #333333;
                font-weight: bold;
            }
            .title_time{    
                font-size: 26px;
                color: #999999;
            }
        }
        .item_info{
            display: flex;
            .info_name{
                font-size: 26px;
                color: #666;
                overflow: hidden; /* 溢出隐藏 */
                text-overflow: ellipsis; /* 文本溢出显示省略号 */
                white-space: nowrap; /* 不换行 */
            }
        }
    }
}
</style>